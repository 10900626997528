import React, { FunctionComponent } from 'react';

import { TermsAndConditionsPageStyle } from './TermsAndConditionsPageStyle';
import Layout from '../../layouts/Layout';
import { Helmet } from 'react-helmet';

const TermsAndConditionsPage: FunctionComponent<{}> = () => (
  <Layout isFAQPage>
    <TermsAndConditionsPageStyle>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms and Conditions</title>
      </Helmet>
      <div className="content-wrapper">
        <div className="content">
          <div className="title">Terms and Conditions</div>
          <div className="note">
            <p>
              Welcome to Travelpix. Before you start enjoying our services we have to set out a few ground rules. We have done our best to cut the legal chat and make them as easy to understand as possible but if you have any questions at all, feel free to contact us at: &nbsp;
              <a target="_blank" href="mailto:team@travelpix.io">team@travelpix.io.</a>
            </p>
          </div>

          <div className="subtitle">WHO WE ARE</div>
          <div className="note">
            <p>
              We are <i>Better Photos Limited</i>, trading as <i>Travelpix</i>, a company incorporated and registered in England and Wales with company number 12411722. Our normal place of business is at 72 Redcliffe Close, Old Brompton Road, London, SW5 9HZ (referred to as Travelpix, we, our and us).
            </p>
            <p>
              We are an online platform which allows you to purchase photographs (featuring you and other individuals) taken by our team of freelance photographers at famous landmarks (the Photographs).
            </p>
          </div>

          <div className="subtitle">THESES TERMS</div>
          <div className="note">
            <p>
              In these terms and conditions, together with the documents referred to in them, (the Terms), we’ll refer to our Website, and our App, and together we’ll refer to them as our Digital Products.
            </p>
            <p>
              When we talk about our Website, we refer to all our sites located at or accessible through www.travelpix.io. These include our owned sites, such as our customer service portal, but do not include links to third-party sites, such as the sites of our partners.
            </p>
            <p>
              When we talk about our App, we refer to our mobile and/or web application, which can be accessed directly through your web browser on a computer or mobile device either as a responsive website or a progressive web application, or downloaded as a native application from third-party platforms, including the Apple App Store and Google Play Store (each, a Third Party Platform)
            </p>
            <p>
              These Terms govern your relationship with us when you access and licence our Digital Products, so please make sure to read them carefully before you start accessing them. Once you start using our Digital Products you are taken to have understood and accepted these Terms.
            </p>
          </div>

          <div className="subtitle">OTHER APPLICABLE TERMS</div>
          <div className="note">
            <p>
              So as not to overload you with too much information at once, these Terms also include our &nbsp;
              <a href="/privacy-policy">Privacy Policy</a>
              .
            </p>
            <p>
              You should read the Privacy Policy as it sets out the terms on which we process (collect, use, share, etc.) any personal data we collect from you or that you provide to us.
            </p>
          </div>

          <div className="subtitle">CHANGES TO THESE TERMS</div>
          <div className="note">
            <p>
              This section informs you of how we may update and amend these Terms.
            </p>
            <p>
              We may from time to time amend these Terms to ensure that we remain compliant with relevant laws and regulations or to keep up to date with improvements or changes we might make to the services and experiences we can offer to you via our Digital Products.
            </p>
            <p>
              We don't expect you to check these Terms every time you use our Digital Products but equally we don't want to bombard you with every little update. We just ask that you check this page from time to time to take notice of any changes we have made. This is important because by continuing to use any of the Digital Products after changes are made, you are accepting those changes and will be bound by them.
            </p>
          </div>

          <div className="subtitle">THE WEBSITE</div>
          <div className="note">
            <p>
              This section tells you what the Website does. We offer the Website mainly in order to:
            </p>
            <ul>
              <li>
                welcome you to and inform you of all our Digital Products;
            </li>
              <li>
                direct you towards the latest version of our App;
            </li>
              <li>
                provide a platform for customer support;
            </li>
              <li>
                link you to our social media channels, such as on Facebook, Twitter and Instagram;
            </li>
              <li>
                provide you with a platform to refer us to other people; and
            </li>
              <li>
                to give you access to our legal and data protection policies.
            </li>
            </ul>
            <p>
              In addition, you will also be able to purchase the Photographs directly from the Website rather than having to do so through the App.
            </p>
            <p>
              If you are seeking to join us as a photographer, you will be able to apply to do so through the Website.
            </p>
            <p>
              Please refer to our &nbsp;
                <a href="/privacy-policy">Privacy Policy</a>
                &nbsp;for more information on how we process your personal data.
            </p>
            <p>
              The list above is not necessarily a complete list of the functions of the Website and we may decide
              to offer additional Website functions, or stop providing any Website functions, at any time.
          </p>
          </div>

          <div className="subtitle">THE APP</div>
          <div className="note">
            <p>
              This section tells you what the App does. We offer various functions via the App (the App
              Functions), including those which allow you to:
            </p>
            <ul>
              <li>
                create a personal Account;
            </li>
              <li>
                access the Photographs which have been taken and to provide you with the ability to purchase
                those Photographs via your Account;
              </li>
              <li>
                link you to our social media channels, such as on Facebook, Twitter and Instagram;
            </li>
              <li>
                provide you with a platform to refer us to other people; and
            </li>
              <li>
                receive notifications via push, emails, SMS and in-app. These messages may include information
                about your Account and general product information. You can control your preferences from your settings.
            </li>
            </ul>
            <p>
              The list above is not necessarily a complete list of the App Functions, and we may decide to offer
              additional or remove some current App Functions, at any time.
            </p>
            <p>
              In addition to these Terms, your use of the App may be subject to any terms and conditions of the
              relevant Third Party Platform from which you download it (Third Party Platform Terms). We have no knowledge of or
              control over any Third Party Platform Terms and you accept the Third Party Platform Terms at your own risk.
            </p>
          </div>

          <div className="subtitle">DOWNLOADING THE APP</div>
          <div className="note">
            <p>
              The App can be downloaded for free from Third Party Platforms. Once downloaded, the App is free to
              access. All costs associated with the purchase of Photographs on the App will be clearly detailed to you.
            </p>
            <p>
              You must be at least 16 years old to download and use the App.
            </p>
            <p>
              By downloading the App, you are agreeing to these Terms and any other applicable terms of
              Travelpix.
            </p>
          </div>

          <div className="subtitle">CREATING AN ACCOUNT</div>
          <div className="note">
            <p>
              To have full access to the App and the App Functions, you will have to register with us and create
              an Account. You can do this by providing your full name; email and/or mobile phone number; and date of birth
              (confirming that you are at least 16 years old). When you provide us with this information, you will be required
              to tick a box to confirm your acceptance to these Terms.
            </p>
            <p>
              Your email address or phone number is used to sign into our Website and/or App, but you can change
              this at any time via your settings. By using the Digital Products and/or signing up with us you agree to any
              applicable terms of the service, including these Terms. Once you have signed up you may be able to provide
              additional optional information including choosing a username, nationality, city of residence, gender and profile
              pictures.
            </p>
            <p>
              Please provide truthful and accurate information when registering with us &ndash; this helps us
              provide you with the best service.
            </p>
            <p>
              You are responsible for looking after the security of your Account information. Please immediately
              notify us of any unauthorised use of your Account or any other breach of security relating to any of the Digital
              Products. We are not responsible for any loss or damage caused by the disclosure of your Account details to
              someone else.
            </p>
            <p>
              You are responsible for ensuring that the information we hold about you is up to date. Please amend
              your details as appropriate from time to time to notify us of any changes.
            </p>
          </div>

          <div className="subtitle">DIGITAL PRODUCTS LICENCE RESTRICTIONS</div>
          <div className="note">
            <p>
              This section tells you what you are not allowed to do with the Digital Products and the
              Photographs.
            </p>
            <p>
              Except as expressly allowed in these Terms, you may not:
            </p>
            <ul>
              <li>
                copy the Digital Products or any of the Photographs available for purchase through the
                App;
              </li>
              <li>
                transfer the Digital Products or Photographs to anyone else;
              </li>
              <li>
                sub-license or otherwise make the Digital Products or Photographs available in whole or in part
                (and whether in object or source code form) to any person;
              </li>
              <li>
                make any alterations to, or modifications of, the Digital Products or Photographs prior to any
                purchase of them; or
              </li>
              <li>
                disassemble, decompile, reverse-engineer or create derivative works based on the whole or any
                part of the Digital Products or Photographs or attempt to do so (together the Digital Products Licence
                Restrictions).
              </li>
            </ul>
          </div>

          <div className="subtitle">ACCEPTABLE USE RESTRICTIONS</div>
          <div className="note">
            <p>
              This section tells you what you can and cannot do with the Digital Products. You may use the
              Digital Products only for personal and lawful purposes.
            </p>
            <p>
              In particular, but without limitation, you agree not to:
            </p>
            <ul>
              <li>
                use the Digital Products in any way that is unlawful or fraudulent, or has any unlawful or
                fraudulent purpose or effect;
              </li>
              <li>
                use, share, or otherwise exploit the Digital Products for any commercial, business, or monetised
                purpose whatsoever;
              </li>
              <li>
                reproduce, duplicate, copy, share, or re-sell any part of the Digital Products in contravention
                of these Terms;
              </li>
              <li>
                use the Digital Products in a way that could damage, disable, overburden, impair or compromise
                our systems or security;
              </li>
              <li>
                use the Digital Products in a way which we deem to be inappropriate or abusive or which could
                cause offence or distress in any way to any staff-member or other person associated with our service;
              </li>
              <li>
                transmit any data, send or upload any material that contains viruses, Trojan horses, worms,
                time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed
                to adversely affect the operation of the Digital Products; or
              </li>
              <li>
                access without authority, interfere with, damage or disrupt (a) any part of the Website or the
                App; (b) any equipment or network on which the Website is stored; (c) any software used in the provision of the
                Website or the App; or (d) any equipment, network or software owned or used by any third party, (together the
                Acceptable Use Restrictions).
              </li>
            </ul>
          </div>

          <div className="subtitle">TERMINATION OF YOUR RIGHTS</div>
          <div className="note">
            <p>
              This section tells you how we can end your rights under these Terms.
            </p>
            <p>
              We may end your rights under these Terms immediately and without notice if:
            </p>
            <ul>
              <li>
                you have breached any of the Digital Products Licence Restrictions or the Acceptable Use
                Restrictions;
              </li>
              <li>
                we believe that your use of the Website or the App is unsuitable in any way; or
              </li>
              <li>
                you are otherwise in breach of these Terms.
              </li>
            </ul>
            <p>
              If we end your rights under these Terms:
            </p>
            <ul>
              <li>
                you must immediately stop all activities authorised by these Terms, including your access to and
                use of any or all of the Digital Products; and
            </li>
              <li>
                if we ask you to, you must immediately delete or remove the App from all devices then in your
                possession, custody or control and, if required, confirm to us that you have done so.
            </li>
            </ul>
          </div>

          <div className="subtitle">INTELLECTUAL PROPERTY RIGHTS</div>
          <div className="note">
            <p>
              This section sets out who owns what in terms of the Digital Products.
            </p>
            <p >
              You agree that the Digital Products and all material published on, in, or via them (including but
              not limited to the Photographs) is owned and controlled by or licensed to us. Upon purchasing any Photographs, you
              shall have unlimited personal use of those purchased Photographs with copyright ownership in the Photographs, any
              copies of Photographers, whether stored digitally or otherwise, and any computer program including any source or
              object code, computer files or printed documentation relating to such images, remaining with us.
            </p>
          </div>

          <div className="subtitle">AVAILABILITY OF THE WEBSITE AND THE APP</div>
          <div className="note">
            <p>
              This section explains that we try to keep the Digital Products up and running at all times but
              cannot promise that this will always be the case.
           </p>
            <p>
              The Digital Products are provided on an &quot;as is&quot; basis. We make no representations,
              warranties or guarantees of any kind regarding the availability or operation of the Digital Products, or that they
              will be secure, uninterrupted or free of defects.
            </p>
            <p>
              Your access to any of the Digital Products may be suspended or restricted occasionally to allow for
              maintenance, repairs, upgrades, or the introduction of new functions or services. Availability of our Digital
              Products may also be interrupted in the case of events or occurrences beyond our reasonable control. We will not
              be liable to you if for any reason any of the Digital Products are unavailable at any time or for any
              period.
          </p>
          </div>

          <div className="subtitle">UPDATES TO THE APP</div>
          <div className="note">
            <p>
              From time to time updates to the App may be issued through the Third Party Platforms or directly
              via our Digital Products. Depending on the update, you may not be able to use the App until you have downloaded
              the latest version of the App and, where applicable, accepted any new terms.
            </p>
          </div>

          <div className="subtitle">WEBSITES WE LINK TO</div>
          <div className="note">
            <p>
              This section informs you of our relationships with any third party websites that feature on our
              Digital Products.
            </p>
            <p>
              The Digital Products may link to other third party websites from which third party services can be
              obtained. Whilst we reasonably believe that these are reputable sources of such services, you acknowledge that
              these other websites are independent from us and we make no representations or warranties as to the legitimacy,
              accuracy or quality of such third party services, and we do not accept any responsibility for their content,
              safety, practices or privacy policies. Essentially, we will do our best to ensure they are safe but you access any
              third party at your own risk.
            </p>
          </div>

          <div className="subtitle">COMPUTER VIRUSES</div>
          <div className="note">
            <p>
              This section provides some helpful advice about how to keep your devices safe from viruses.
            </p>
            <p>
              We do everything we can to ensure that no part of the Digital Products will contain or spread any
              viruses or other malicious code but this section explains how you can best protect your devices. &nbsp;
            </p>
            <p>
              We recommend that you ensure that equipment used to access the Digital Products run up-to-date
              anti-virus software as a precaution, and you are advised to virus-check any materials downloaded from any of the
              Digital Products and regularly check for the presence of viruses and other malicious code.
            </p>
            <p>
              To the full extent permitted by law we exclude liability for damage or loss of any kind caused by
              viruses or other harmful components originating or contracted from the Digital Products.
            </p>
          </div>

          <div className="subtitle">NO RELIANCE ON INFORMATION</div>
          <div className="note">
            <p>
              All information published on or via the Digital Products is provided in good faith and for general
              information purpose only. We make no warranties about the completeness, reliability, or accuracy of such
              information. Any action you take based on such information is taken at your own risk.
            </p>
          </div>

          <div className="subtitle">OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</div>
          <div className="note">
            <p>
              This section explains the limits of our liability to you where you suffer any loss or damage from
              any of the Digital Products.
            </p>
            <p>
              We do not exclude or limit our liability to you where it would be unlawful to do so. This includes
              liability for death or personal injury caused by our negligence or the negligence of our employees, agents or
              subcontractors and for fraud or fraudulent misrepresentation.
            </p>
            <p>
              We provide the Digital Products for domestic and private use only. You agree not to use any of them
              for any commercial or business purposes, and we will have no liability to you for any loss of profit, loss of
              business, business interruption, or loss of business opportunity.
            </p>

            <p>
              We are not responsible for the acts or omissions of any Third Party Platform.
            </p>
            <p>
              You accept and agree we will not be liable for any harmful effect that accessing the Digital
              Products and/or any Photographs may have on you, and you agree that you access the Digital Products at your own
              risk. In addition, you accept and agree that we will not be liable in the event that there is any loss of or
              damage to the Photographs.
            </p>
          </div>

          <div className="subtitle">IF THERE IS A PROBLEM WITH THE WEBSITE AND/OR APP</div>
          <div className="note">
            <p>
              This section explains how you can tell us about any problems with any of the Digital Products and
              what your legal rights are.
            </p>
            <p>
              If you have any questions or complaints about the Website or App, please contact us. You can
              contact us at team@travelpix.io &nbsp; &nbsp;
            </p>
          </div>

          <div className="subtitle">COMMUNICATIONS BETWEEN US</div>
          <div className="note">
            <p>
              This section explains how you can contact us and how and when we may contact you.
            </p>
            <p>
              <span>If you wish to contact us for any reason, you can do so via </span>
              <span>
                <a target="_blank" href="mailto:team@travelpix.io">team@travelpix.io</a>
              </span>
              <span>.</span>
            </p>
            <p>
              We will only contact you if we make any relevant updates or changes to our services, or where you
              have signed up for marketing communications. You may opt out of marketing communications at any time by using the
              &quot;unsubscribe&quot; link provided or via your settings within the App.
            </p>

            <p>
              The App may use pop-up notifications, unless you disable them. Please note though that it is not
              possible to disable service information or error alerts. &nbsp;
            </p>
          </div>

          <div className="subtitle">OTHER IMPORTANT TERMS</div>
          <div className="note">
            <p>
              This section provides all of the extra terms that we have to tell you about.
           </p>
            <p>
              We may transfer our rights and obligations under these Terms to another organisation, but this will
              not affect your rights or our obligations under these Terms.
            </p>
            <p>
              Under these Terms, you are granted a licence only in respect of our Digital Products. We retain
              ownership in the Digital Products at all times.
            </p>
            <p>
              If we do not enforce our rights against you, or if we delay in doing so, that does not mean that we
              have waived our rights against you, and it does not mean that you are relieved of your obligations under these
              Terms. If we do waive a breach by you, we will only do so in writing, and that will not mean that we will
              automatically waive any later breach by you.
            </p>
            <p>
              Each of the terms and conditions of these Terms operates separately. If any court or competent
              authority decides that any of them are unlawful or unenforceable, the remaining terms and conditions will remain
              in full force and effect.
            </p>
            <p>
              These Terms are governed by English law and the courts of England and Wales have non-exclusive
              jurisdiction.
            </p>
          </div>
        </div>
      </div>
    </TermsAndConditionsPageStyle >
  </Layout >
);

export default TermsAndConditionsPage;
